import React, { useEffect, createRef } from 'react';
import { MainAnimation, OpacityWrapper } from './styles';
import lottie from 'lottie-web/build/player/lottie_svg';


const Map = () => {
    // const animationContainer = createRef();

    // useEffect(() => {
    //     const anim = lottie.loadAnimation({
    //         container: animationContainer.current,
    //         renderer: 'svg',
    //         loop: true,
    //         autoplay: true,
    //         animationData: animation,
    //     });
    //     return () => anim.destroy();
    // }, []);

    return (
        <OpacityWrapper>
            {/* <MainAnimation ref={animationContainer} top={-2} /> */}
        </OpacityWrapper>

    )
};

export default Map;
