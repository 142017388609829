import React, { useState, useEffect } from 'react';

import {
  Wrapper, Line, Dot, RedLine, RedDot, DotContainer,
  ArrowDot, Arrow, AnimatedHoverCircle, OnSlideChangeAnimation,
  DateArrow, DateContainer, TitleContainer, GradientContainer,
  ToolTip,
} from './styles';
import { getLangFromPath } from 'src/utils/index.js';

const Timeline = ({
  handleIndex,
  dots,
  yearsArray,
  titleArray,
  toolTipDesc,
}) => {
  const [red, setRed] = useState(100 / (dots + 1));
  const [activeIndex, setActiveIndex] = useState(0);
  const [oldIndex, setOldIndex] = useState(0);
  const [madeAnimationRight, setAnimationRight] = useState(false);
  const [madeAnimationLeft, setAnimationLeft] = useState(false);
  const [clickedArrow, setClickedArrow] = useState(false);

  const timeline = React.createRef();

  const setNewPosition = (index, vector) => {
    if (index >= 0 && index <= dots - 1) {
      if (vector === 'left') {
        setAnimationLeft(true);
      } else if (vector === 'right') {
        setAnimationRight(true);
      }
      if (!clickedArrow) {
        setClickedArrow(true);
      }
      const oneWidth = 100 / (dots + 1);
      const elementWidth = oneWidth * (index + 1);
      setRed(elementWidth);
      setOldIndex(activeIndex);
      setActiveIndex(index);
      handleIndex(index);
    }
  };
  const onAnimationEndLeft = () => {
    setAnimationLeft(false);
  };

  const onAnimationEndRight = () => {
    setAnimationRight(false);
  };
  const dotArray = [];
  for (let i = 0; i < dots; i++) {
    dotArray.push(i);
  }
  const allDots = dotArray.map((dot, index) => {
    const oneWidth = 100 / (dots + 1);
    const leftPosition = oneWidth * (index + 1);

    const style = {
      position: 'absolute',
      left: `${leftPosition}%`,
      cursor: `pointer`
    }
    let exception = null;
    // yearsArray.forEach((item, i) => {
    //   if (item.index === index) {
    //     exception = item;      }
    // });
    let title = null;
    titleArray.forEach((item, i) => {
      if (item.index === index) {
        title = item;
      }
    });
    const titleStyle = {
      left: `${leftPosition + 0.25}%`,
      cursor: `pointer`,
      fontSize: getLangFromPath() === 'DE' || getLangFromPath() === 'HU' ? '0.6rem' : '',
      lineHeight: getLangFromPath() === 'RU' ? '0.8rem' : '',

    }
    let showTitle = title ? <TitleContainer style={titleStyle} geramnOne={getLangFromPath() === 'DE'} onClick={() => { setNewPosition(index) }} positionTop={title.position === "top"} positionBottom={title.position === "bottom"} > {title.text} </TitleContainer> : '';
    return (
      <div key={index}>
        {showTitle}
        <DotContainer style={style} onClick={() => { setNewPosition(index) }}>
          <Dot />
          {(activeIndex >= index)
            && <RedDot
              widthElement={activeIndex === index && oldIndex - activeIndex < 0}
              showAll={index <= activeIndex}
            />}
        </DotContainer>
      </div>
    )
  })

  const ToolTip1 = {
    height: '8rem',
    top: '-11rem'
  }

  const addStyleToGermanTooltip = getLangFromPath() === 'DE' ? ToolTip1 : {};

  return (
    <Wrapper>
      <GradientContainer />
      <Line ref={timeline}>
        <ArrowDot isLeft onClick={() => setNewPosition(activeIndex - 1, 'right')} isShowing={true} style={{ display: activeIndex === 0 ? 'none' : 'flex' }}>
          {(madeAnimationRight)
            && <OnSlideChangeAnimation onAnimationEnd={onAnimationEndRight} />}
          <AnimatedHoverCircle />
          <Arrow isLeft />
        </ArrowDot>
        <ArrowDot isRight onClick={() => setNewPosition(activeIndex + 1, 'left')} isShowing={true} style={{ display: activeIndex >= dots - 1 ? 'none' : 'flex' }} geramnOne={getLangFromPath() === 'DE'}>
          <ToolTip clickedArrow={clickedArrow} style={addStyleToGermanTooltip}>
            {toolTipDesc}
          </ToolTip>
          {(madeAnimationLeft)
            && <OnSlideChangeAnimation onAnimationEnd={onAnimationEndLeft} />}
          <AnimatedHoverCircle />
          <Arrow isRight />
        </ArrowDot>
        <RedLine redWidth={red} />
        {allDots}
      </Line>
    </Wrapper>
  )
};

export default Timeline;
