import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/de/chapter_4/sub_13/slide1';

import WrapperMobile from 'src/slides/mobile/de/chapter_4/sub_13/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from '../../../components/_shared/seo';

// desktop

const allSlides = [
  <Slide1 />,
];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Was passierte nach dem Sieg I. Schlacht bei Warschau" description="Die Verteidigung Lembergs, Kampf um Zadwórze, Schlacht an der Memel - der Sieg bei Warschau beendete nicht den Krieg." lang="de" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

// mobile


const Mobile = () => (
  <>
    <SEO title="Was passierte nach dem Sieg I. Schlacht bei Warschau" description="Die Verteidigung Lembergs, Kampf um Zadwórze, Schlacht an der Memel - der Sieg bei Warschau beendete nicht den Krieg." lang="de" />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};
export default Page;
