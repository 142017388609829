import React, { useEffect, createRef } from 'react';
import { MainAnimation, OpacityWrapper, Background } from './styles';
import lottie from 'lottie-web/build/player/lottie_svg';
import animation from './animations/slide-5.json';


const Map = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation,
        });
        return () => anim.destroy();
    }, []);

    return (
        <OpacityWrapper>
            <Background src={require(`../../../../assets/images/chapter_4/sub_13/4-13-4_icons.svg`)} />
            <MainAnimation ref={animationContainer} top={-0.5} right={1} />
        </OpacityWrapper>
    )
};

export default Map;
