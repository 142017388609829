import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux"
import actions from "src/store/actions"
import { Wrapper } from './styles';
import Description from '../description/description';
import Map from '../map/map';
import Timeline from '../timeline/timeline';
import InitialView from '../initialView/initialView';
import { motion, AnimatePresence } from "framer-motion"

const variants = {
    initial: {
        opacity: 0,
    },
    enter: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.3 },
    },
}

const MainWrapper = ({ data, assets, modals, quote, titles, initialScreen, theme }) => {
    const [initialScreenVisible, setInitialScreenVisible] = useState(true);
    const [mapsScreenVisible, setMapsScreenVisible] = useState(false);
    const dataArray = data;
    const [contentIndex, setContentIndex] = useState(0);
    const dispatch = useDispatch()

    const handleIndex = (ix) => {
        if (ix === contentIndex) return;
        setContentIndex(ix);
    }

    const hideInitialScreen = () => {
        setInitialScreenVisible(false)
    }

    useEffect(() => {
        if (mapsScreenVisible) {
          dispatch({ type: actions.SET_SLIDER_THEME, payload: theme })
        }
        return () => {
          dispatch({ type: actions.SET_SLIDER_THEME, payload: null })
        }
      }, [mapsScreenVisible])


    return (
        <>
            <AnimatePresence
                initial={false}
                onExitComplete={() => setMapsScreenVisible(true)} >
                {initialScreenVisible && (
                    <motion.div
                        key={initialScreenVisible}
                        variants={variants}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                    >
                        <InitialView data={initialScreen} assets={assets} hideInitialScreen={hideInitialScreen} />
                    </motion.div>
                )
                }
            </AnimatePresence >
            {mapsScreenVisible && <Wrapper>
                <Description
                    dataArray={dataArray}
                    contentIndex={contentIndex}
                    assets={assets}
                />
                <Map index={contentIndex} assets={assets} modals={modals} quote={quote} />
                <Timeline
                    index={contentIndex}
                    titleArray={titles.exports.titleArray}
                    toolTipDesc={titles.frontmatter.toolTip}
                    handleIndex={handleIndex}
                    dots={9}
                />
            </Wrapper>}
        </>
    )
};

export default MainWrapper;
