import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import MapSlider from '../../../../../components/desktop/mapSlider3/mainWrapper/mainWrapper';
import { SlideLarge } from '../../_styles';

const Slide = ({
  query, modals, quote, titles, initialScreen,
}) => {
  const assetQuery = useStaticQuery(graphql`
    query {
      paralax1: file(relativePath: { eq: "chapter_4/sub_13/paralaxa_1.png"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      },
      paralax2: file(relativePath: { eq: "chapter_4/sub_13/paralaxa_2.png"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      },
      paralax3: file(relativePath: { eq: "chapter_4/sub_13/paralaxa_3.png"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      },
      img_4_13_1: file(relativePath: { eq: "chapter_4/sub_13/4-13-1.png"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      },
  modalImage1: file(relativePath: { eq: "chapter_4/sub_13/modal-4-13-1.1.png"}) {
    childImageSharp {
      fixed(width: 459, height: 745)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  img_4_13_3: file(relativePath: { eq: "chapter_4/sub_13/4-13-3.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  img_4_13_8: file(relativePath: { eq: "chapter_4/sub_13/4-13-8.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  praglowski: file(relativePath: { eq: "chapter_4/sub_13/praglowski.png"}) {
    childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
  boguski: file(relativePath: { eq: "chapter_4/sub_13/boguski.png"}) {
    childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
  brzeszczynski: file(relativePath: { eq: "chapter_4/sub_13/brzeszczynski.png"}) {
    childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
}
  `);
  return (
    <SlideLarge>
      <MapSlider
        data={query}
        assets={assetQuery}
        modals={modals}
        quote={quote}
        titles={titles}
        initialScreen={initialScreen}
        theme="dark"
      />
    </SlideLarge>
  );
};

export default Slide;
