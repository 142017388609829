import React, { useEffect, createRef, useState } from 'react';
import { motion, AnimatePresence } from "framer-motion"

import { EmptyWrapper, OpacityWrapper, Background } from './styles';


const Map1 = () => {
    return (
        <EmptyWrapper>
        </EmptyWrapper>
    )
}

const Map2 = ({ lang }) => {

    return (
        <EmptyWrapper >
            <Background src={require(`../../../../assets/images/chapter_4/sub_13/${lang}/4-13-6_2.svg`)} />
        </EmptyWrapper>
    )
}

const variants = {
    initial: {
        opacity: 0,
    },
    enter: {
        opacity: 1,
        transition: { duration: 1 },
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 },
    },
}

const Map = ({ lang }) => {
    const [mapIndex, setMapIndex] = useState(0);
    const maps = [<Map1 />, <Map2 lang={lang} />]

    useEffect(() => {
        const interval = setInterval(() => {
            mapIndex < maps.length - 1 ? setMapIndex(index => index + 1) : setMapIndex(0);
        }, 2000);

        return () => clearInterval(interval);
    }, [mapIndex]);


    return (
        <OpacityWrapper>
            <AnimatePresence
                initial={false}
            >
                <motion.div
                    key={mapIndex}
                    variants={variants}
                    initial="initial"
                    animate="enter"
                    exit="exit"
                >
                    {maps[mapIndex]}
                </motion.div>
            </AnimatePresence >

        </OpacityWrapper>
    )
};

export default Map;
