import React from 'react';
import { AssetsBackground, OpacityWrapper } from './styles';
import Marker from 'src/components/desktop/marker/marker';

const Modals = ({ assets, modals, language }) => {

    const modal1 = {
        PL: 'Konstanty Zarugiewicz',
        RU: 'Константин Заругевич',
        DE: 'Konstanty Zarugiewicz',
        EN: 'Konstanty Zarugiewicz',
        FR: 'Konstanty Zarugiewicz',
        UA: 'Костянтин Заругевич',
        HU: 'Konstanty Zarugiewicz',
    }
    const modal2 = {
        PL: 'Zacięty bój',
        RU: 'Жестокая битва',
        DE: 'Ein heftiger Kampf',
        EN: 'Fierce battle',
        FR: 'Une bataille acharnée',
        UA: 'Жорстокий бій',
        HU: 'Heves csata',
    }

    return (
        <>
            <AssetsBackground src={assets.img_4_13_1.childImageSharp.fluid.src} />
            <Marker
                positionRight="54%"
                positionTop="65%"
                body={modals[0].body}
                text={modal1[language]}
                additionalStyling={{ textTransform: 'none', fontSize: '1rem' }}
                image={assets.modalImage1.childImageSharp.fixed}
            />
            <Marker
                positionRight="46%"
                positionTop="56%"
                body={modals[1].body}
                text={modal2[language]}
                additionalStyling={{ textTransform: 'none', fontSize: '1rem' }}
            // image={assetQuery.modal2.childImageSharp.fixed}
            />
        </>
    )
};

export default Modals;
