import React, { useEffect, createRef } from 'react';
import { OpacityWrapper, MainAnimation } from './styles'
import lottie from 'lottie-web/build/player/lottie_svg';
import animation from './animations/slide-1.json';


const Map = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            // path: '/assets/json/chapter_1/sub_3/data.json',
            animationData: animation,
        });
        setTimeout(() => { anim.play(); }, 750);
        return () => anim.destroy(); // optional clean up for unmounting
    }, []);

    return (
        <OpacityWrapper initialAnimation={true}>
            <MainAnimation ref={animationContainer} top={-.5} right={.5} />
        </OpacityWrapper>
    )
};

export default Map;
