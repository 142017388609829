import React, { useEffect, createRef, useState } from 'react';


import { MainAnimation, EmptyWrapper, OpacityWrapper } from './styles';




const Map = () => {

    return (
        <OpacityWrapper>

        </OpacityWrapper>
    )
};

export default Map;
