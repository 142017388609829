import styled, { css, keyframes } from 'styled-components';
import { fonts, colors } from '../../../../../../globals';

const textAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 70px, 0)
  }
  to {
    opacity: 1;
    transform: translate3d(0, -2px, 0)
  }
`;


export const Wrapper = styled.div`
    width: 30%;
    /* max-width: 500px; */
    padding-top: 7%;
    /* background: #322B2B; */
    overflow: hidden;
    @media(max-width: 1100px) {
      padding-top: 3%;
    }
`;
export const Content = styled.div`
    width: 75%;
    max-width: 22rem;
    margin: 0 auto;
    position: relative;

    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}

    & h2 {
        ${{ ...fonts.headline_2 }}
        /* margin-bottom: 0; */
        ${({ animate }) => animate
    && css`
        opacity: 0;
        animation: ${textAnimation} 0.8 cubic-bezier(0.78, 0, 0.235, 1) .5s forwards;
        `}

    }
    & h3 {
        ${{ ...fonts.headline_4 }}
        text-transform: uppercase;
        font-weight: 500;
        ${({ animate }) => animate
    && css`
        opacity: 0;
        animation: ${textAnimation} 0.8 cubic-bezier(0.78, 0, 0.235, 1) .7s forwards;
        `}
    }

    & p {

        ${{ ...fonts.popup }}
        ${({ animate }) => animate

    && css`
        opacity: 0;
        animation: ${textAnimation} 0.8 cubic-bezier(0.78, 0, 0.235, 1) .9s forwards;
        `}
        @media(max-width: 1100px) {
          font-size: 0.75rem;
          line-height: 1rem;
        }
    }
`;


export const Text = styled.div`
      position: absolute;
      width: 100%;
      max-width: 25rem;
      transition: all .5s;
      p {
        display: inline;
      }
      z-index: 10;
`;
