import React, { useState, useEffect } from 'react';
import { Wrapper, MapContainer, Background, Texture } from './styles';
import { Preload } from 'react-preload';
import texture from 'src/assets/images/chapter_3/sub_5/_shared/texture.png';
import { motion, AnimatePresence } from "framer-motion"
import { getLangFromPath } from 'src/utils/index.js'

import SubMap1 from './slide1';
import SubMap2 from './slide2';
import SubMap3 from './slide3';
import SubMap4 from './slide4';
import SubMap5 from './slide5';
import SubMap6 from './slide6';
import SubMap7 from './slide7';
import SubMap8 from './slide8';
import SubMap9 from './slide9';


const duration = 1

const variants = {
    initial: {
        opacity: 0,
    },
    enter: {
        opacity: 1,
        transition: { duration: duration },
    },
    exit: {
        opacity: 0.7,
        transition: { duration: duration },
    },
}

const Map = ({ index, assets, modals, quote }) => {
    const lang = getLangFromPath();

    const BACKGROUNDS = {
        0: require(`../../../../assets/images/chapter_4/sub_13/${lang}/4-13-0.svg`),
        1: assets[`img_4_13_1`].childImageSharp.fluid.src,
        2: require(`../../../../assets/images/chapter_4/sub_13/${lang}/4-13-2.svg`),
        3: assets[`img_4_13_3`].childImageSharp.fluid.src,
        4: require(`../../../../assets/images/chapter_4/sub_13/${lang}/4-13-4.svg`),
        6: require(`../../../../assets/images/chapter_4/sub_13/${lang}/4-13-6_1.svg`),
        7: require(`../../../../assets/images/chapter_4/sub_13/${lang}/4-13-7.svg`),
        8: assets[`img_4_13_8`].childImageSharp.fluid.src,
    }

    const windowDimentions = window.innerWidth / 1920 * 1.1;

    const noBackground = 5;
    const [mapScale, setMapScale] = useState(windowDimentions);
    const [initialAnimation, setInitialAnimation] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);

    window.addEventListener('resize', () => {
        setMapScale(window.innerWidth / 1920 * 1.05);
    })


    const SLIDES = [<SubMap1 />, <SubMap2 assets={assets} modals={modals} language={lang} />,
    <SubMap3 />, <SubMap4 />,
    <SubMap5 />, <SubMap6 />,
    <SubMap7 lang={lang} />, <SubMap8 />,
    <SubMap9 />]

    useEffect(() => {
    }, [index]);

    useEffect(() => {
        setImageLoaded(false);
        index === 1 && setImageLoaded(true);
        index > 0 && setInitialAnimation(false);
    }, [index])

    const handleOnLoad = () => {
        setImageLoaded(true);
    }



    return (
        <Wrapper >
            <Preload
                images={Object.values(BACKGROUNDS)}
                autoResolveDelay={5000}
                resolveOnError={true}
                mountChildren={true}
            >
                <>
                    <MapContainer
                        style={{ transform: `scale(${mapScale})` }}
                        fixedSize={true}
                        scaleDown={index !== noBackground ? 1 / mapScale : 1}
                    >

                        <AnimatePresence
                            initial={false}
                        // onExitComplete={() => setMapsScreenVisible(true)}
                        >
                            <motion.div
                                key={index}
                                variants={variants}
                                initial="initial"
                                animate="enter"
                                exit="exit"
                            >
                                {index !== noBackground && <Background
                                    key={index}
                                    src={BACKGROUNDS[index]}
                                    alt="map"
                                    index={index}
                                    onLoad={handleOnLoad}
                                    animate={initialAnimation}
                                />}
                                {SLIDES[index]}
                            </motion.div>
                        </AnimatePresence >
                    </MapContainer>
                    {index !== 1 && index !== 5 && index !== 8 &&
                        <Texture src={texture} animate={initialAnimation}
                        />}
                </>
            </Preload>
        </Wrapper>
    )
};

export default React.memo(Map);
