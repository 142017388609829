import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Wrapper, LeftContent, RightContent, Button, ArrowContainer, Arrow } from './styles';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import arrowImg from 'src/assets/images/_shared/button-arrow.svg';

const InitialView = ({ data, assets, hideInitialScreen }) => {

    return (

        <ParallaxMordo additionalStyling={{ overflow: 'visible' }}>
            <Mordo
                mainscene
                move={{ x: -20, y: -20 }}
                background={{ image: assets.paralax1.childImageSharp.fluid }}
            />
            <Mordo
                scene={1}
                scenelayer={{ size: '100%' }}
                move={{ x: -50, y: -25 }}
                background={{ image: assets.paralax2.childImageSharp.fluid }}
            />
            <Mordo
                scene={2}
                scenelayer={{ size: '100%' }}
                move={{ x: 25, y: 10 }}
                background={{ image: assets.paralax3.childImageSharp.fluid }}
            />
            <Mordo
                flexi
                scene={2}
                move={{ x: -10, y: -10 }}
            >
                <Wrapper>
                    <MDXProvider components={{
                        LeftContent,
                        RightContent,
                        Button,
                        ArrowContainer,
                        Arrow,
                        p: (props) => <p {...props} tabIndex="0" />,
                    }}>
                        <MDXRenderer arrow={arrowImg} handleHide={hideInitialScreen}>
                            {data}
                        </MDXRenderer>
                    </MDXProvider>
                </Wrapper>
            </Mordo>
        </ParallaxMordo>

    )
};

export default React.memo(InitialView);
