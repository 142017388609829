import React, { useEffect, createRef, useState } from 'react';
import { motion, AnimatePresence } from "framer-motion"

import { MainAnimation, EmptyWrapper, OpacityWrapper, Background } from './styles';
import lottie from 'lottie-web/build/player/lottie_svg';
import animation1 from './animations/slide-4_1.json';
import animation2 from './animations/slide-4_2.json';
import animation3 from './animations/slide-4_3.json';
import animation4 from './animations/slide-4_4.json';

const Map1 = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation1,
        });

        return () => anim.destroy();

    }, []);

    return (
        <EmptyWrapper>
            <Background src={require(`../../../../assets/images/chapter_4/sub_13/4-13-3_icons_1.svg`)} />
            <MainAnimation ref={animationContainer} />
        </EmptyWrapper>
    )
}

const Map2 = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation2,
        });

        return () => anim.destroy();

    }, []);
    return (
        <EmptyWrapper >
            <Background src={require(`../../../../assets/images/chapter_4/sub_13/4-13-3_icons_2.svg`)} />
            <MainAnimation ref={animationContainer} />
        </EmptyWrapper>
    )
}

const Map3 = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation3,
        });

        return () => anim.destroy();

    }, []);
    return (
        <EmptyWrapper >
            <Background src={require(`../../../../assets/images/chapter_4/sub_13/4-13-3_icons_3.svg`)} />
            <MainAnimation ref={animationContainer} />
        </EmptyWrapper>
    )
}

const Map4 = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation4,
        });

        return () => anim.destroy();

    }, []);
    return (
        <EmptyWrapper >
            <Background src={require(`../../../../assets/images/chapter_4/sub_13/4-13-3_icons_4.svg`)} />
            <MainAnimation ref={animationContainer} />
        </EmptyWrapper>
    )
}


const variants = {
    initial: {
        opacity: 0,
    },
    enter: {
        opacity: 1,
        transition: { duration: 1 },
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 },
    },
}

const Map = () => {
    const [mapIndex, setMapIndex] = useState(0);
    const maps = [<Map1 />, <Map2 />, <Map3 />, <Map4 />]

    useEffect(() => {
        const interval = setInterval(() => {
            mapIndex < maps.length - 1 ? setMapIndex(index => index + 1) : setMapIndex(0);
        }, 5500);

        return () => clearInterval(interval);
    }, [mapIndex]);


    return (
        <OpacityWrapper>
            <AnimatePresence
                initial={false}
            >
                <motion.div
                    key={mapIndex}
                    variants={variants}
                    initial="initial"
                    animate="enter"
                    exit="exit"
                >
                    {maps[mapIndex]}
                </motion.div>
            </AnimatePresence >

        </OpacityWrapper>
    )
};

export default Map;
