import React, { useEffect, useState } from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import { getLangFromPath } from 'src/utils/index.js';
import { compose } from 'redux';
import { mediaServer } from '../../../../../globals';

import { Wrapper, Content, Text } from './styles';

const setStyles = (ix, contentIndex) => {
  if (ix === contentIndex) {
    return {
      transform: 'translate3d(0, 0, 0)',
      opacity: 1,
    };
  }

  if (ix < contentIndex) {
    return {
      position: 'absolute',
      transform: 'translate3d(-200%, 0, 0)',
      opacity: ix < contentIndex ? 0 : 1,
    };
  }

  if (ix > contentIndex) {
    return {
      position: 'absolute',
      transform: 'translate3d(200%, 0, 0)',
      opacity: ix > contentIndex ? 0 : 1,
    };
  }
};

const Description = ({ dataArray, contentIndex, assets }) => {
  const [initialAnimation, setInitialAnimation] = useState(true);
  const language = getLangFromPath().toLowerCase();

  useEffect(() => {
    contentIndex > 0 && setInitialAnimation(false);
  }, [contentIndex]);


  return (
    <Wrapper>
      <Content animate={initialAnimation}>
        {dataArray.map((el, ix) => (
          <Text
            key={ix}
            style={setStyles(ix, contentIndex)}
          >
            <MDXProvider components={{
              p: (props) => <p {...props} tabIndex="0" />,
              AudioPlayer,

            }}
            >
              <MDXRenderer
                praglowskiAudio={[`${mediaServer}/webm/${language}/413praglowski.webm`, `${mediaServer}/mp4/${language}/413praglowski.mp4`]}
                praglowskiImage={assets.praglowski.childImageSharp.fluid}
                boguskiAudio={[`${mediaServer}/webm/${language}/413boguski.webm`, `${mediaServer}/mp4/${language}/413boguski.mp4`]}
                boguskiImage={assets.boguski.childImageSharp.fluid}
                brzeszczynskiAudio={[`${mediaServer}/webm/${language}/413brzeszczynski.webm`, `${mediaServer}/mp4/${language}/413brzeszczynski.mp4`]}
                brzeszczynskiImage={assets.brzeszczynski.childImageSharp.fluid}
              >
                {el.node.body}
              </MDXRenderer>
            </MDXProvider>
          </Text>
        ))}
      </Content>
    </Wrapper>
  );
};

export default React.memo(Description);
